@use "../consts";

.container {
  max-width: 1200px;
  margin: 5% auto;
  &-title {
    margin-top: 30px;
  }
}

.filter {
  display: flex;
  flex-wrap: wrap;
  margin: 2% 10% 5% 0%;
  &-search {
    width: 240px;
    margin-right: 50px;
  }
  &-select {
    width: 220px;
    margin-right: 50px;
  }
  &-sort {
    padding-top: 5px;
    margin-left: 10px;
    width: 80px;
  }
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  text-align: left;

  &-item {
    font-size: 20px;
    width: 190px;
    overflow: hidden;
    margin: 20px;
    text-align: center;
    &-title {
      font-size: 0.9rem;
      letter-spacing: -0.4px;
      font-weight: 600;
      margin-bottom: 5px;
      line-height: 1rem;
      text-align: left;
    }

    &-publish-date {
      font-size: 0.7rem;
      letter-spacing: -0.4px;
      margin: 0;
      line-height: 1rem;
      text-align: left;
    }
  }
}
