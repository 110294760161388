.dropdown {
  &-icon {
    width: 21px;
    margin-right: 5px;
  }
  &-menu-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-menu-trigger {
    background: #ffffff;
    border-radius: 90px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-left: auto;
    &:hover {
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    }
    span {
      vertical-align: middle;
      font-size: 14px;
      margin: 0 10px;
    }
    img {
      border-radius: 90px;
    }
  }
  &-menu {
    padding: 0;
    display: block;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 60px;
    right: 0;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      a,
      button {
        text-decoration: none;
        color: #333333;
        padding: 15px 20px;
        display: block;
        border: 0;
        background-color: inherit;
        font-weight: 100;
      }

      &:hover {
        background-color: whitesmoke;
      }
    }
  }
  &-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(-16px);
  }
}

.underlined {
  border-bottom: 1px solid #dddddd;
}

.profile-image {
  height: 28px;
  width: 28px;
}
