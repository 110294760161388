@use "../consts";

.labels-container {
  display: flex;
  flex-wrap: wrap;
  &-item {
    height: 200px;
    width: 200px;
    background-color: consts.$fika-black;
    margin: 20px;
    // text-align: center;
    align-items: center;
    justify-content: center;
  }
  &-item-title {
    font-size: 1.5rem;
    letter-spacing: -0.4px;
    font-weight: 600;
    line-height: 1rem;
    color: white;
  }
}

.category-container {
  display: flex;
  // justify-content: center;
  text-align: left;
  &-item {
    font-size: 20px;
  }
}
