@font-face {
  font-family: 'Futura';
  src: url('./fonts/futura/futur.ttf');
}

@font-face {
  font-family: 'Futura Medium';
  src: url('./fonts/futura/futura\ medium\ bt.ttf');
}

body {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Futura' !important;
}