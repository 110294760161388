.share-container {
  display: flex;
  justify-content: center;
  &-button {
    width: 20px !important;
  }
}

.video-player-container {
  position: relative;
  max-width: 1000px;
  margin: auto;
}

.video-player {
  margin: 5% auto;
}

.video-info {
  margin: 50px auto;
}

.video-info h3 {
  margin: 20px auto;
}

iframe {
  display: block;
  border-style: none;
}
