

.signup{
  &-container {
    max-width: 400px;
    margin: 0 auto;
  }
  &-header-container{
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
  }
  &-form {
    label {
      font-weight: 600;
    }
  }
  &-logo {
    width: 400px;
  }
}
