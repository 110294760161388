.admin-container {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 83px);
}

.sidebar-nav-container {
  flex-direction: column;
  width: 200px;
  padding: 20px;
  box-shadow: 0 0.125rem 9.375rem rgb(90 97 105 / 10%),
    0 0.25rem 0.5rem rgb(90 97 105 / 12%),
    0 0.9375rem 1.375rem rgb(90 97 105 / 10%),
    0 0.4375rem 2.1875rem rgb(165 182 201 / 10%);
}
