@use "../consts";

.featured-media {
  background-color: consts.$fika-black;
  padding: 85px;
  &-container {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
  &-text-container {
    color: white;
    width: 50%;
  }
  &-image-container-temp {
    padding: 0%;
    width: 400px;
    height: 250px;
    background-color: white;
  }
  &-title {
    color: white;
  }
  &-subtitle {
    color: white;
    margin-top: 20px;
  }
}
