table {
  font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  color: black;
}

.managerContainer {
  display: flex-row;
  justify-content: space-between;
  margin: 20px 10px 20px 50px;
  width: auto;
}

.display-manager-container {
  display: flex-row;
  justify-content: space-between;
  margin: 20px 10px 20px 50px;
  width: auto;
  min-width: 1000px;
}

.display-manager-container FormTextarea {
  height: 85px;
}

.columnTitle:hover {
  cursor: pointer;
}

.tableRow:hover {
  background-color: #ddd;
}
