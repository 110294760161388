.nav-links {
  width: 400px;
  display: flex;
  justify-content: space-between;
  color: black;
}

.navbar {
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  font-family: 'Futura Medium' !important;
  &-brand {
    color: black !important;
    font-size: 26px;
  }
}

.nav-item {
  a {
    color: black !important;
    font: normal normal medium 20px/27px Futura;
  }
}

.signup {
  background-color: red !important;
  border-radius: 0;
  outline: none;
  border: none;
  font-weight: 600;
}