@use "../consts";

.frame {
  body {
    div {
      background-color: green !important;
    }
  }
}

.listen-container {
  max-width: 1200px;
  margin: 2% auto;
  &-title {
    margin-top: 30px;
  }
}

.listen-podcast {
  padding: 2%;
}

.thumbnail {
  width: 100%;
  padding-bottom: 10px;
}

.episodes-container {
  display: flex;
  flex-wrap: wrap;
}

.episode {
  &-container {
    width: 200px;
    overflow: hidden;
    margin: 20px;
    text-align: center;
  }
  &-title {
    font-size: 0.9rem;
    letter-spacing: -0.4px;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: 1rem;
    text-align: left;
  }

  &-publish-date {
    font-size: 0.7rem;
    letter-spacing: -0.4px;
    margin: 0;
    line-height: 1rem;
    text-align: left;
  }
}

.featured-media {
  background-color: consts.$fika-black;
  padding: 85px;
  &-container {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
  &-text-container {
    color: white;
    width: 50%;
  }
  &-image-container {
    padding: 0%;
  }
  &-title {
    color: white;
  }
  &-subtitle {
    color: white;
    margin-top: 20px;
  }
}

.category-container {
  display: flex;
  // justify-content: center;
  text-align: left;
  &-item {
    font-size: 20px;
  }
  &-title {
    color: #007bff;
    text-decoration: none;
  }
  &-title:hover {
    color: #015ab9;
  }
}
