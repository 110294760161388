@use '../consts';

.banner {
  &-image {
    &-container {
      padding: 120px 0;
      background-color: consts.$fika-black;
    }
  }
}

.header {
  &-title {
    text-align: center;
    font-size: 65px;
    color: white;
    line-height: 67px;
  }
  &-subtitle {
    text-align: center;
    color: white;
    margin-top: 50px;
  }
}

.home-nav {
  &-section {
    margin: 112px auto;
  }
  &-text {
    text-align: center;
  }
  &-container {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
  }
  &-button {
    margin-top: 80px;
    width: 167px;
  }
}

.featured-podcast {
  background-color: consts.$fika-black;
  padding: 85px;
  &-container {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
  &-text-container {
    color: white;
    width: 50%;
  }
  &-image-container {
    width: 400px;
    height: 300px;
    background-color: white;
  }
  &-title {
    color: white;
  }
  &-subtitle {
    color: white;
    margin-top: 20px;
  }
  &-button {
    width: 167px;
    margin-top: 50px;
  }
}

.home-container {
  margin: auto;
}

.section-container h1 {
  font-size: 40px;
}

.section-container {
  margin: 100px auto;

  &-right {
    text-align: end;
  }
  &-left {
    text-align: left;
  }
}

.description {
  font-size: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  &-item-right {
    width: 50%;
    text-align: end;
  }
  &-item-left {
    width: 50%;
    text-align: left;
  }
}

.player-conatiner {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &-podcast {
    height: 250px;
    width: 500px;
    // background-color: consts.$fika-black;
    margin: 40px;
    align-items: center;
  }
  &-video {
    height: 350px;
    width: 500px;
    // background-color: consts.$fika-black;
    margin: 40px;
    align-items: center;
  }
  &-blog {
    height: 500px;
    width: 500px;
    background-color: consts.$fika-black;
    margin: 40px;
    align-items: center;
  }
}

.home-about {
  &-section {
    margin: 120px auto;
  }

  &-title {
    text-align: center;
    font-size: 32px;
  }
}
