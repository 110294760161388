.share-container {
  display: flex;
  justify-content: center;
  &-button {
    width: 20px !important;
  }
}

.podcast-player-container {
  position: relative;
  max-width: 1000px;
  margin: auto;
}

.podcast-info {
  margin: 50px auto;
}

.podcast-info h3 {
  margin: 20px auto;
}

.podcast-player {
  margin: 5% auto;
}
